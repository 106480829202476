<template>
  <div class="learningPath-body">
    <TheNav />

    <div class="content">
      <div class="tabs">
        <button
          v-for="(item, index) in categoryResult"
          :key="item.id"
          @click="changeList(item, index)"
        >
          <span :class="{ active: cla == index }">{{ item.pathName }}</span>
        </button>
      </div>
      <div class="cards">
        <div
          class="card-content"
          v-for="step in categoryResult[whichPath].pathInfo"
          :key="step.stepNum"
        >
          <p>{{ step.stepName }}</p>
          <div class="card" v-for="item in step.list" :key="item.link">
            <a
              :class="step.stepNum"
              :href="item.link"
              target="_blank"
              class="title"
            >
              {{ item.title }}
            </a>
          </div>
        </div>
      </div>
      <div class="height"></div>
    </div>
  </div>

  <TheFooter />
</template>

<script>
import learningPath from "../assets/learningPath.json";
import { onMounted, ref, computed } from "vue";
import TheFooter from "../views/TheFooter.vue";
import TheNav from "../views/TheNav.vue";
export default {
  setup() {
    components: {
      TheFooter, TheNav;
    }

    const cla = ref("0");
    const whichPath = ref(0);
    const categoryResult = ref(learningPath);

    function changeList(item, index) {
      console.log(item);
      cla.value = index;
      whichPath.value = index;
    }
    return {
      TheFooter,
      TheNav,
      learningPath,
      changeList,
      cla,
      categoryResult,
      whichPath,
    };
  },
};
</script>

<style scoped>
.learningPath-body {
  width: 100%;
  min-height: 800px;
  background: #f5f6f9f8;
}
.content {
  width: 950px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.tabs {
  width: 250px;
}

.tabs button {
  display: block;
  padding: 15px 0;
  padding-left: 12px;
  padding-right: 18px;
  border: none;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.tabs span {
  color: rgb(136, 136, 136);
  border-left: #fff solid 2px;
  padding-left: 5px;
  cursor: pointer;
}

.tabs button:hover span {
  color: #222;
  border-left: rgb(124, 177, 0) solid 2px;
}
.content .tabs .active {
  color: #222;
  border-left: rgb(124, 177, 0) solid 2px;
}

.cards {
  width: 700px;
  background: darkgoldenrod;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 100px;
}

.card-content {
  border: #e3eaef solid 1px;
  border-radius: 8px;
  padding: 20px 30px 25px;
  margin-bottom: 25px;
}

.card-content p {
  color: #777d7f;
  font-weight: bold;
}

.card {
  margin-top: 25px;
  padding: 0, 15px;
}

.card .title {
  font-size: 0.85em;
  padding: 10px 15px;
  border-radius: 30px;
}

.card-content .one {
  background: #d9e5ff;
  color: #2165d1;
}
.card-content .one:hover {
  background: #2165d1;
  color: #fff;
}
.card-content .two {
  background: #dceddd;
  color: #38823a;
}
.card-content .two:hover {
  background: #38823a;
  color: #fff;
}
.card-content .three {
  background: #ffe5b2;
  color: #b88217;
}
.card-content .three:hover {
  background: #b88217;
  color: #fff;
}
.card-content .four {
  background: #fddcda;
  color: #c7382d;
}
.card-content .four:hover {
  background: #c7382d;
  color: #fff;
}

.height {
  height: 120px;
}

@media screen and (max-width: 470px) {
  .content {
    width: 95%;
    flex-wrap: wrap;
  }
  .tabs {
    width: 100%;
  }

  .tabs button {
    margin: 0;
    margin-bottom: 15px;
    margin-right: 10px;
    display: inline-block;
  }

  .tabs button {
    font-size: 0.8em;
  }

  .cards {
    width: 100%;
    padding: 15px;
    margin-bottom: 60px;
  }

  .card-content {
    border: #e3eaef solid 1px;
    border-radius: 8px;
    padding: 20px 10px 25px;
    margin-bottom: 25px;
  }

  .card-content p {
    color: #777d7f;
    font-weight: bold;
  }

  .card {
    margin-top: 25px;
    padding: 0, 12px;
  }

  .card .title {
    font-size: 0.8em;
    padding: 8px;
    border-radius: 30px;
  }
}

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .content {
    width: 95%;
    flex-wrap: wrap;
  }
  .tabs {
    width: 100%;
  }

  .tabs button {
    margin: 0;
    margin-bottom: 15px;
    margin-right: 10px;
    display: inline-block;
  }

  .tabs button {
    font-size: 0.8em;
  }

  .cards {
    width: 100%;
    padding: 15px;
    margin-bottom: 60px;
  }

  .card-content {
    border: #e3eaef solid 1px;
    border-radius: 8px;
    padding: 20px 10px 25px;
    margin-bottom: 25px;
  }

  .card-content p {
    color: #777d7f;
    font-weight: bold;
  }

  .card {
    margin-top: 25px;
    padding: 0, 12px;
  }

  .card .title {
    font-size: 0.8em;
    padding: 10px;
    border-radius: 30px;
  }
}
</style>